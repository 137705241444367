import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Arrow from "../icons/arrow";

const FullLogoBlock = ({ title, text, link, linkText, logoImage }) => {
  return (
    <section className={`pb-10 pt-20`}>
      <div className={`mx-auto max-w-screen-xl w-full px-6`}>
        {title && <h2 class="mb-6 font-medium text-center">{title}</h2>}
        <div className={`w-2/5 m-auto pb-8`}>
          <GatsbyImage
            image={getImage(logoImage)}
            className={"w-full h-full object-cover"}
            layout="fullWidth"
          />
        </div>
        <div className="max-w-5xl px-6 m-auto text-center">
          <div className="mb-10 text-[17px] md:text-[24px] md:leading-9">
            {text}
          </div>
          <Link
            className={`text-[#448FDE] flex items-center justify-center`}
            to={link}
          >
            <span className="block mr-4">{linkText}</span>
            <Arrow color={`#448FDE`} />
          </Link>
        </div>
        {/* <div className={`flex w-full flex-wrap md:flex-nowrap justify-center items-center`}>
                <div className={`w-full md:w-1/4 md:py-6 h-full`}>
                    <div className={`md:pr-20 h-full w-48 md:w-full`}>
                        <GatsbyImage
                        image={getImage(logoImage)}
                        className={"w-full h-full object-cover"}
                        layout="fullWidth"
                        />
                    </div>
                </div>
                <div>{title}</div>
                <div className={`w-full md:w-3/4 py-6 md:pl-20 md:border-l border-[#E2E2E2]`}>
                    <h2 className="mb-6 text-blue">{text}</h2>
                    <Link className={`text-[#448FDE] flex items-center`} to={link}>
                        <span className="block mr-4">{linkText}</span><Arrow color={`#448FDE`} />
                    </Link>
                </div>
            </div> */}
      </div>
    </section>
  );
};

FullLogoBlock.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export default FullLogoBlock;
