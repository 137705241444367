import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Quotes from "../components/Quotes";
import FullLogoBlock from "../components/FullLogoBlock";
import Video from "../video/findurfit.mp4";

const FitnessPageTemplate = ({
  hero,
  intro,
  card,
  pageText,
  pageFooterText,
  quotes,
  fullLogoBlock,
}) => {
  return (
    <div className="section">
      <Hero
        image={hero.image}
        title={hero.title}
        link={hero.link}
        linkText={hero.linkText}
      />
      <Intro
        introImage={intro.introImage}
        title={intro.title}
        text={intro.text}
        link={intro.link}
        linkText={intro.linkText}
        introToggle={intro.introToggle ? intro.introToggle : false}
      />
      <div className="relative w-full max-w-screen-xl px-6 mx-auto mt-10 md:mt-20">
        <video controls width="100%" poster="/img/video-placeholder.png">
          <source src={Video} type="video/mp4" />
          <track kind="captions" label="English" src="/path/to/captions.vtt" />
        </video>
      </div>
      <div id="programme" className="relative -top-20"></div>
      <div
        className={`mx-auto max-w-screen-xl w-full px-6 text-[17px] md:text-[26px] md:leading-9`}
      >
        <div className="max-w-5xl py-16 m-auto text-center md:py-20">
          {pageText}
        </div>
      </div>

      {card.map((item, index) => (
        <div
          className={`${
            ["bg-[#448FDE] text-white", "bg-white", "bg-blue text-white"][
              index % 3
            ]
          } md:h-[350px] relative`}
          key={index}
        >
          <div
            className={`md:absolute h-[250px] top-0 w-full md:w-1/2 md:h-full ${
              index % 2 === 0 ? "left-0" : "right-0"
            }`}
          >
            <GatsbyImage
              image={getImage(item.image)}
              className={"w-full h-full object-cover"}
              layout="fullWidth"
            />
          </div>

          <div
            className={`mx-auto max-w-screen-xl w-full px-6 h-full flex flex-col justify-center
      ${index % 2 === 0 ? "items-end" : ""}`}
          >
            <div
              className={`flex flex-col justify-center w-full h-full py-12 md:py-0 md:w-1/2
        ${index % 2 === 0 ? "md:pl-14" : "md:pr-14"}`}
            >
              <h2 className={`mb-4 md:mb-8`}>{item.title}</h2>
              <div>{item.text}</div>
            </div>
          </div>
        </div>
      ))}

      <div
        className={`mx-auto max-w-screen-xl w-full px-6 text-[17px] md:text-[26px] md:leading-9`}
      >
        <div className="max-w-5xl py-16 m-auto text-center md:py-20">
          {pageFooterText}
        </div>
      </div>

      <section className={`mx-auto max-w-screen-xl w-full px-6 mb-16`}>
        <div className={`bg-[#448FDE] text-white p-8 lg:px-16 lg:py-10`}>
          <div
            className={`flex-wrap lg:flex-nowrap flex w-full items-center justify-center lg:justify-between`}
          >
            <div className={`w-[135px] lg:w-[148px] mb-6 lg:mb-0`}>
              <StaticImage
                src="../images/fitness-bites.svg"
                alt="Fitness Bites"
                layout="fullWidth"
              />
            </div>

            <div
              className={`w-full lg:w-2/5 text-center lg:text-left mb-6 lg:mb-0`}
            >
              <b className={`font-semibold tc-h1`}>Ready to get started?</b>
              <p className={`tc-h2`}>Subscribe for monthly workouts & more</p>
              <p className={`tc-h2`}>Only £19.99 per month</p>
            </div>

            <a
              rel="noopener noreferrer"
              href="https://findurfit.heymarvelous.com/buy/product/54247"
              target="_blank"
              className={`bg-white text-sm text-[#448FDE] inline-block font-semibold py-2 px-6 rounded-[10px]`}
            >
              Subscribe
            </a>
          </div>
        </div>
      </section>
      <hr />

      <Quotes quotes={quotes} />
      <div id="corporate" className="relative -top-24"></div>
      <FullLogoBlock
        title={fullLogoBlock.title}
        logoImage={fullLogoBlock.logoImage}
        text={fullLogoBlock.text}
        link={fullLogoBlock.link}
        linkText={fullLogoBlock.linkText}
      />
    </div>
  );
};

FitnessPageTemplate.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const FitnessPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo title={`Fitness Bites`} description={frontmatter.intro.text} />
      <FitnessPageTemplate
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        pageText={frontmatter.pageText}
        pageFooterText={frontmatter.pageFooterText}
        card={frontmatter.card}
        quotes={frontmatter.quotes}
        fullLogoBlock={frontmatter.fullLogoBlock}
      />
    </Layout>
  );
};

FitnessPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FitnessPage;

export const fitnessPageQuery = graphql`
  query FitnessPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          link
          linkText
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        intro {
          title
          text
          link
          linkText
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        pageText
        card {
          title
          text
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        pageFooterText
        quotes {
          quote
          author
        }
        fullLogoBlock {
          title
          text
          link
          linkText
          logoImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
